import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
// import BlogPage from './pages/BlogPage';
// import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
// import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import ProtectedRoute from './custom_routes/ProtectedRoute';
import useAxiosPrivate from './hooks/useAxiosPrivate';
import { setAccessToken, setUser, setDeepAuthAccessToken } from './features/auth/authSlice';
import { getRefreshToken } from './api/refreshToken';
import PasswordResetForm from './pages/PasswordResetForm';
import ResetPasswordConfirm from './pages/ResetPasswordConfirm';
import useRefreshToken from './hooks/useRefreshToken';
import WalletPage from './pages/WalletPage';
import LogsPage from './pages/LogsPage';
import Reports from './pages/Reports';
import Bav from './sections/backoffice/Bav';
import Pan from './sections/backoffice/Pan';
import Aadhar from './sections/backoffice/Aadhar';
import Cheque from './sections/backoffice/Cheque';
import VoterID from './sections/backoffice/VoterId';
import DrivingL from './sections/backoffice/DrivingLicense';
import { apiSlice, useGetCredentialsQuery, useUserMutation } from './features/api/apiSlice';
import { useGetAccessTokenForApiMutation } from './features/api/publicApiSlice';
import KycPan from './pages/KycPan';
import KycPanAdv from './pages/KycPanAdv';
import KycUpi from './pages/KycUpi';
import KycBav from './pages/KycBav';
import KycGstinBasic from './pages/KycGstinBasic';
import KycGstinByPan from './pages/KycGstinByPan';
import BankChequeOcr from './pages/BankChequeOcr';
import PanOcr from './pages/PanOcr';
import AadhaarOcr from './pages/AadhaarOcr';
import VoterIdOcr from './pages/VoterIdOcr';
import DrivingLicenseOcr from './pages/DrivingLicenseOcr';
import KycIfsc from './pages/KycIfsc';
import KycUdyam from './pages/KycUdyam';
import { onTidioChatApiReady } from './utils/tidioChat';
import KycUdyog from './pages/KycUdyog';
import PhotocopyOcr from './pages/PhotocopyOcr';
import DocumentOcr from './pages/DocumentOcr';
import Referral from './pages/Referral';
import RcAdvanced from './pages/KycRcAdvanced';
import KycPanPlus from './pages/KycPanPlus';
import PanMSMECheck from './pages/PanMSMECheck';
import CINVerification from './pages/CINVerification';
import DINVerification from './pages/DINVerification';
import KycGstinAdv from './pages/KycGstinAdv';
import TDSComplainceCheck from './pages/TDSComplainceCheck';
import MobileToVPA from './pages/MobileToVPA';
import KycAadhaarBasic from './pages/KycAadhaarBasic';
import GstinCertificateOcr from './pages/GstinCertificateOcr';
import Facematch from './pages/Facematch';
import LivenessCheck from './pages/LivenessCheck';
import AadhaarMasking from './pages/AadhaarMasking';
import PanAadhaarLinkStatus from './pages/PanAadhaarLinkStatus';
import KycDrivingLicense from './pages/KycDrivingLicense';
import KycVoterId from './pages/KycVoterId';
import NameMatch from './pages/NameMatch';
import ApiResponseTime from './pages/ApiResponseTime';
import Credentials from './pages/Credentials';
import UserNewEditForm from './pages/UserNewEditForm';
import Account from './pages/Account';
import HomePage from './pages/HomePage';
import BankAccount from './pages/BankAccount';
import KycBatchBav from './pages/KycBatchBav';
import PanAccount from './pages/PanAccount';
import BatchPanPlus from './pages/BatchPanPlus';
import MobileToName from './pages/MobileToName';
import AadhaarToUan from './pages/AadhaarToUan';
import PanToUan from './pages/PanToUan';
import UanToEmploymentHistory from './pages/UanToEmploymentHistory';
import UPIAdvanced from './pages/UpiAdvance';
import MobileToPan from './pages/MobileToPan';
import MobileToDrivingLicense from './pages/MobileToDrivingLicense';
import MobileToUan from './pages/MobileToUan';
import MobileToUanList from './pages/MobileToUanList';
import MobileToDigitalAge from './pages/MobileToDigitalAge';
import MobileNetworkDetails from './pages/MobileNetworkDetails';
import MobileToMultipleUPI from './pages/MobileToMultipleUpi';
import TeamMembers from './pages/TeamMembers';
import PanBasicAccount from './pages/PanBasicAccount';
import BatchPanBasic from './pages/BatchPanBasic';
import GSTINBasic from './pages/GstinBasic';
import BatchGstinBasic from './pages/BatchGstinBasic';
import Udyam from './pages/Udyam';
import BatchUdyamAadhaar from './pages/BatchUdyamAadhaar';
import Udyog from './pages/Udyog';
import BatchUdyogAadhaar from './pages/BatchUdyogAadhaar';
import CreditReport from './pages/CreditReport';
import AadhaarEkyc from './pages/AadhaarEkyc';
import Digilocker from './pages/Digilocker';
import DigilockerLandingPage from './pages/DigilockerLandingPage';
import Aadhaar from './pages/Aadhaar';
import BatchAadhaarBasic from './pages/BatchAadhaarBasic';
import BatchCINVerification from './pages/BatchCINVerification';
import Upi from './pages/Upi';
import BatchUpiBasic from './pages/BatchUpiBasic';
import GstinAdvance from './pages/GstinAdvance';
import BatchGstinAdvance from './pages/BatchGstinAdvance';
import GstinByPan from './pages/GstinByPan';
import BatchGstinByPan from './pages/BatchGstinByPan';
import BatchMobileToPan from './pages/BatchMobileToPan';
import MobileToPanContainer from './pages/MobileToPanContainer';
import Cin from './pages/Cin';
import Fssai from './pages/Fssai';
import Passport from './pages/Passport';
import PanToFatherName from './pages/PanToFatherName';
import PanToKra from './pages/PanToKra';

// ----------------------------------------------------------------------
export default function Router() {
    const dispatch = useDispatch();
    const refresh = useRefreshToken();
    const [getUser] = useUserMutation();
    const { user, access_token } = useSelector((state) => {
        return state.auth.auth;
    });

    const { data: credentialsData, isSuccess, refetch: refetchGetCredentials } = useGetCredentialsQuery();
    const [getAccessToken] = useGetAccessTokenForApiMutation();
    useEffect(() => {
        refetchGetCredentials();
        return () => { };
    }, [access_token]);
    useEffect(() => {
        if (isSuccess) {
            getAccessToken(credentialsData[0])
                .unwrap()
                .then((res) => {
                    dispatch(
                        setDeepAuthAccessToken({
                            access_token: res.access_token,
                            client_secret: credentialsData[0]?.client_secret,
                            client_id: credentialsData[0]?.client_id,
                        })
                    );
                });
        }
        return () => { };
    }, [credentialsData, isSuccess, getAccessToken, dispatch]);

    useEffect(() => {
        (async () => {
            try {
                const access_token = await refresh();
                dispatch(setAccessToken(access_token));
                if (getRefreshToken()) {
                    const rcvd_user = await getUser().unwrap();
                    dispatch(setUser(rcvd_user));
                }
            } catch (error) {
                console.error(error);
            }
        })();
        document.querySelector('#app-loading').classList.add('loaded');
    }, []);

    useEffect(() => {
        if (window.tidioChatApi) {
            window.tidioChatApi.on('ready', () => onTidioChatApiReady(user));
        } else {
            document.addEventListener('tidioChat-ready', () => onTidioChatApiReady(user));
        }
        return () => {
            document.removeEventListener('tidioChat-ready', () => { });
        };
    }, [user]);
    return (
        <>
            <Routes>
                <Route path="/dashboard" element={<ProtectedRoute redirectPath="/login" child={<DashboardLayout />} />}>
                    <Route index element={<Navigate to="/dashboard/home" />} />
                    <Route path="app" element={<DashboardAppPage />} />
                    <Route path="home" element={<HomePage />} />
                    <Route path="account" element={<Account />}>
                        <Route index element={<Navigate to="api-keys" />} />
                        <Route path="api-keys" element={<Credentials />} />
                        <Route path="account-info" element={<UserNewEditForm />} />
                        <Route path="team-members" element={<TeamMembers />} />
                    </Route>
                    <Route path="logs" element={<LogsPage />} />
                    <Route path="logs/api-response-time" element={<ApiResponseTime />} />
                    {/* <Route path="user" element={<UserPage />} /> */}
                    {/* <Route path="products" element={<ProductsPage />} /> */}
                    <Route path="reports" element={<Reports />} />
                    <Route path="back-office" index element={<Navigate to="/dashboard/back-office/aadhar" />} />
                    <Route path="back-office/bav" element={<Bav />} />
                    <Route path="back-office/pan" element={<Pan />} />
                    <Route path="back-office/aadhar" element={<Aadhar />} />
                    <Route path="back-office/cheque" element={<Cheque />} />
                    <Route path="back-office/voter" element={<VoterID />} />
                    <Route path="back-office/dl" element={<DrivingL />} />
                    <Route
                        path="verification"
                        index
                        element={<Navigate to="/dashboard/verification/aadhaar/aadhaar-basic" />}
                    />
                    <Route path="verification/aadhaar" element={<Aadhaar />}>
                        <Route index element={<Navigate to="aadhaar-basic" />} />
                        <Route path="aadhaar-basic" element={<KycAadhaarBasic />} />
                        <Route path="batch-aadhaar-basic" element={<BatchAadhaarBasic />} />
                    </Route>
                    <Route path="verification/aadhaar-ekyc" element={<AadhaarEkyc />} />
                    <Route path="verification/digilocker" element={<Digilocker />} />
                    <Route path="verification/credit-report" element={<CreditReport />} />
                    <Route path="verification/bank-account" element={<BankAccount />}>
                        <Route index element={<Navigate to="bav" />} />
                        <Route path="bav" element={<KycBav />} />
                        <Route path="batch-bav" element={<KycBatchBav />} />
                    </Route>
                    <Route path="verification/pan-account" element={<PanAccount />}>
                        <Route index element={<Navigate to="pan-plus" />} />
                        <Route path="pan-plus" element={<KycPanPlus />} />
                        <Route path="batch-pan-plus" element={<BatchPanPlus />} />
                    </Route>
                    <Route path="verification/pan" element={<PanBasicAccount />}>
                        <Route index element={<Navigate to="pan-basic" />} />
                        <Route path="pan-basic" element={<KycPan />} />
                        <Route path="batch-pan-basic" element={<BatchPanBasic />} />
                    </Route>
                    <Route path="verification/gstin" element={<GSTINBasic />}>
                        <Route index element={<Navigate to="gstin-basic" />} />
                        <Route path="gstin-basic" element={<KycGstinBasic />} />
                        <Route path="batch-gstin-basic" element={<BatchGstinBasic />} />
                    </Route>
                    <Route path="verification/udyam" element={<Udyam />}>
                        <Route index element={<Navigate to="udyam-aadhaar" />} />
                        <Route path="udyam-aadhaar" element={<KycUdyam />} />
                        <Route path="batch-udyam" element={<BatchUdyamAadhaar />} />
                    </Route>
                    <Route path="verification/udyog" element={<Udyog />}>
                        <Route index element={<Navigate to="udyog-aadhaar" />} />
                        <Route path="udyog-aadhaar" element={<KycUdyog />} />
                        <Route path="batch-udyog" element={<BatchUdyogAadhaar />} />
                    </Route>
                    <Route path="verification/pan-adv" element={<KycPanAdv />} />
                    <Route path="verification/rc-adv" element={<RcAdvanced />} />

                    <Route path="verification/upi" element={<Upi />} >
                        <Route index element={<Navigate to="upi-basic" />} />
                        <Route path="upi-basic" element={<KycUpi />} />
                        <Route path="batch-upi-basic" element={<BatchUpiBasic />} />
                    </Route>

                    <Route path="verification/upi-advanced" element={<UPIAdvanced />} />
                    <Route path="verification/ifsc" element={<KycIfsc />} />
                    <Route path="verification/bav" element={<KycBav />} />

                    <Route path="verification/gstin-advance" element={<GstinAdvance />} >
                        <Route index element={<Navigate to="gstin-adv" />} />
                        <Route path="gstin-adv" element={<KycGstinAdv />} />
                        <Route path="batch-gstin-adv" element={<BatchGstinAdvance />} />
                    </Route>

                    <Route path="verification/gstin-pan" element={< GstinByPan />} >
                        <Route index element={<Navigate to="gstin-by-pan" />} />
                        <Route path="gstin-by-pan" element={<KycGstinByPan />} />
                        <Route path="batch-gstin-by-pan" element={<BatchGstinByPan />} />
                    </Route>

                    <Route path="verification/cin" element={<Cin />}>
                        <Route index element={<Navigate to="cin-verification" />} />
                        <Route path="cin-verification" element={<CINVerification />} />
                        <Route path="batch-cin" element={<BatchCINVerification />} />
                    </Route>

                    <Route path="verification/din-verification" element={<DINVerification />} />
                    <Route path="verification/tds-complaince" element={<TDSComplainceCheck />} />
                    <Route path="verification/mobile-to-vpa" element={<MobileToVPA />} />
                    <Route path="verification/mobile-to-name" element={<MobileToName />} />

                    <Route path="verification/mobile-to-pan-details" element={<MobileToPanContainer />}>
                        <Route index element={<Navigate to="mobile-to-pan" />} />
                        <Route path="mobile-to-pan" element={<MobileToPan />} />
                        <Route path="batch-mobile-to-pan" element={<BatchMobileToPan />} />
                    </Route>

                    <Route path="verification/mobile-to-driving-license" element={<MobileToDrivingLicense />} />
                    <Route path="verification/mobile-to-digital-age" element={<MobileToDigitalAge />} />
                    <Route path="verification/mobile-to-network-details" element={<MobileNetworkDetails />} />
                    <Route path="verification/mobile-to-multiple-upi" element={<MobileToMultipleUPI />} />
                    <Route path="verification/mobile-to-uan" element={<MobileToUan />} />
                    <Route path="verification/mobile-to-uan-list" element={<MobileToUanList />} />
                    <Route path="verification/aadhaar-to-uan" element={<AadhaarToUan />} />
                    <Route path="verification/pan-to-uan" element={<PanToUan />} />
                    <Route path="verification/uan-to-employ-history" element={<UanToEmploymentHistory />} />
                    <Route path="verification/facematch" element={<Facematch />} />
                    <Route path="verification/liveness-check" element={<LivenessCheck />} />
                    <Route path="verification/aadhaar-masking" element={<AadhaarMasking />} />
                    <Route path="verification/pan-aadhaar-link-status" element={<PanAadhaarLinkStatus />} />
                    <Route path="verification/driving-license" element={<KycDrivingLicense />} />
                    <Route path="verification/voter-id" element={<KycVoterId />} />
                    <Route path="verification/name-match" element={<NameMatch />} />
                    <Route path="verification/cheque-ocr" element={<BankChequeOcr />} />
                    <Route path="verification/pan-ocr" element={<PanOcr />} />
                    <Route path="verification/aadhaar-ocr" element={<AadhaarOcr />} />
                    <Route path="verification/gstin-ocr" element={<GstinCertificateOcr />} />
                    <Route path="verification/voter-id-ocr" element={<VoterIdOcr />} />
                    <Route path="verification/driving-license-ocr" element={<DrivingLicenseOcr />} />
                    <Route path="verification/pan-msme" element={<PanMSMECheck />} />
                    <Route path="verification/photocopy" element={<PhotocopyOcr />} />
                    <Route path="verification/documentocr" element={<DocumentOcr />} />
                    <Route path="verification/fssai" element={<Fssai />} />
                    <Route path="verification/passport" element={<Passport />} />
                    <Route path="verification/pan-to-father-name" element={<PanToFatherName />} />
                    <Route path="verification/pan-to-kra" element={<PanToKra />} />


                    <Route path="referral/" element={<Referral />} />
                </Route>
                <Route path="/kyc/digilocker/digilocker-code" element={<DigilockerLandingPage />} />
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute redirectPath="/dashboard/app" permission="wallet" child={<DashboardLayout />} />
                    }
                >
                    <Route path="wallet" element={<WalletPage />} />
                </Route>
                <Route element={<SimpleLayout />}>
                    <Route index element={<Navigate to="/dashboard/home" />} />
                    <Route path="404" element={<Page404 />} />
                    <Route path="*" element={<Page404 />} />
                    <Route path="/reset_password" element={<PasswordResetForm />} />
                    <Route path="/reset_password/:uid/:token" element={<ResetPasswordConfirm />} />
                </Route>
                <Route path="/login" element={<LoginPage />} />
            </Routes>
        </>
    );
}
