import React, { useState } from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    InputLabel,
    Modal,
    TextField,
    CircularProgress,
    Typography,
} from '@mui/material';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useFormik } from 'formik';
import { modalStyles as style } from '../utils/cssStyles';
import { useFssaiMutation } from '../features/api/deepApiSlice';

const Fssai = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [getData, { data, isLoading }] = useFssaiMutation();

    const formik = useFormik({
        initialValues: {
            fssai_id: '',
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const response = await getData({ fssai_id: values.fssai_id }).unwrap();
                if (response.code === 200) {
                    if (response.sub_code === 'INVALID_FSSAI_ID') {
                        setErrorMessage('Invalid FSSAI ID.');
                    } else {
                        setErrorMessage('');
                        setModalOpen(true);
                    }
                }
            } catch (err) {
                setErrorMessage(err?.data?.message || 'Something went wrong, please try again.');
            }
        },
    });

    const handleCloseModal = () => {
        setModalOpen(false);
        formik.resetForm();
    };

    return (
        <>
            <Modal open={modalOpen} onClose={handleCloseModal}>
                <Box sx={{ ...style, width: '80vw', maxHeight: '70vh', overflowY: 'auto' }}>
                    <Box
                        mb={3}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        FSSAI Verified Successfully &nbsp;
                        <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>

                    <Grid container mb={3} sx={{ overflowY: 'auto', maxHeight: '70vh' }}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                FSSAI Number
                            </Grid>
                            <Grid item>{data?.data?.fssai_number || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Application Number
                            </Grid>
                            <Grid item>{data?.data?.application_number || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Company Name
                            </Grid>
                            <Grid item>{data?.data?.details?.[0]?.company_name || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Address
                            </Grid>
                            <Grid item xs={6} sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                {data?.data?.details?.[0]?.address_premises || '-'}
                            </Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Pincode
                            </Grid>
                            <Grid item>{data?.data?.details?.[0]?.pincode || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                License Status
                            </Grid>
                            <Grid item>{data?.data?.details?.[0]?.status_desc || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                District
                            </Grid>
                            <Grid item>{data?.data?.details?.[0]?.district_name || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                District Premises
                            </Grid>
                            <Grid item>{data?.data?.details?.[0]?.district_premises || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Taluk
                            </Grid>
                            <Grid item>{data?.data?.details?.[0]?.taluk_name || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Taluk Premises
                            </Grid>
                            <Grid item>{data?.data?.details?.[0]?.taluk_premises || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                State
                            </Grid>
                            <Grid item>{data?.data?.details?.[0]?.state_name || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                State Premises
                            </Grid>
                            <Grid item>{data?.data?.details?.[0]?.state_premises || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Application Type
                            </Grid>
                            <Grid item>{data?.data?.details?.[0]?.app_type_desc || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                License Category
                            </Grid>
                            <Grid item>{data?.data?.details?.[0]?.license_category_name || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Application Submission Date
                            </Grid>
                            <Grid item>{data?.data?.details?.[0]?.app_submission_date || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Last Updated On
                            </Grid>
                            <Grid item>{data?.data?.details?.[0]?.last_updated_on || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Reference ID
                            </Grid>
                            <Grid item>{data?.data?.details?.[0]?.ref_id || '-'}</Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="FSSAI License Check"
                        subheader="Verify FSSAI License Number and retrieve FBO details."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Fssai ID</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    inputProps={{
                                        maxLength: 14,
                                    }}
                                    placeholder="Fssai ID"
                                    size="small"
                                    type="text"
                                    name="fssai_id"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            {errorMessage && (
                                <Grid item>
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {errorMessage || 'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default Fssai;
