import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { modalStyles as style } from '../utils/cssStyles';
import { useGetCompanyMasterDataMutation } from '../features/api/deepApiSlice';

const CINVerification = () => {
    const paid_up_capital = 'paid_up_capital(rs)';
    const authorised_capital = 'authorised_capital(rs)';
    const company_status = 'company_status(for_efiling)';
    const previous_firm = 'previous_firm/_company_details,_if_applicable';
    const [modalOpen, setModalOpen] = useState(false);
    const [getCompanyMasterData, { data, isLoading, isError, error }] = useGetCompanyMasterDataMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: async (values) => {
            await getCompanyMasterData({ id_number: values.id_number }).unwrap();
            setModalOpen(true);
        },
    });

    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={2}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Company Master Data &nbsp; <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3} sx={{ overflowY: 'scroll', maxHeight: '70vh' }}>
                        {data?.data?.company_master_data? (
                            <>
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Company Name
                                    </Grid>
                                    <Grid item xs={6} sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }} >{data?.data?.company_master_data?.company_name || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Company Catogery
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.company_category || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Email ID
                                    </Grid>
                                    <Grid item xs={6} sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{data?.data?.company_master_data?.email_id || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Class of Company
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.class_of_company || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Date of last AGM
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.date_of_last_agm || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Registered Address
                                    </Grid>
                                    <Grid item xs={6} sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{data?.data?.company_master_data?.registered_address || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Registration Number
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.registration_number || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Paid-up Capital
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.[paid_up_capital] || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Is Listed
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.whether_listed_or_not || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Suspended at Stock Exchange
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.suspended_at_stock_exchange || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        CIN
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.cin || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Company Subcategory
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.company_subcategory || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Authorised Capital
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.[authorised_capital] || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Company Status (for e-filing)
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.[company_status] || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        ROC Code
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.roc_code || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Date of Balance Sheet
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.date_of_balance_sheet || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Date of Incorporation
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.date_of_incorporation || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Active Compliance
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.active_compliance || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Previous Firm (if applicable)
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.[previous_firm] || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Number of Designated Partners
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.number_of_designated_partners || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Total obligation of contribution
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.total_obligation_of_contribution || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Description of Main Division
                                    </Grid>
                                    <Grid item xs={6} sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }} >{data?.data?.company_master_data?.description_of_main_division || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Number of Partners
                                    </Grid>
                                    <Grid item>{data?.data?.company_master_data?.number_of_partners || '-'}</Grid>
                                </Grid>
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Directors/Signatory Details
                                    </Grid>
                                    <Grid item>
                                        {data?.data?.['directors/signatory_details']?.length > 0 ? (
                                            data.data['directors/signatory_details'].map((director, index) => (
                                                <Box key={index} mb={2}>
                                                    <Divider sx={{ width: '100%' }} flexItem />
                                                    <Grid container flexDirection="row" my={0.5}>
                                                        <Grid item color="gray" sm={6}>
                                                            Name
                                                        </Grid>
                                                        <Grid item xs={6} sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{director.name || '-'}</Grid>
                                                    </Grid>
                                                    <Grid container flexDirection="row" my={0.5}>
                                                        <Grid item color="gray" sm={6}>
                                                            DIN/PAN
                                                        </Grid>
                                                        <Grid item>{director['din/pan'] || '-'}</Grid>
                                                    </Grid>
                                                    <Grid container flexDirection="row" my={0.5}>
                                                        <Grid item color="gray" sm={6}>
                                                            Designation
                                                        </Grid>
                                                        <Grid item >{director.designation || '-'}</Grid>
                                                    </Grid>
                                                    <Grid container flexDirection="row" my={0.5}>
                                                        <Grid item color="gray" sm={6}>
                                                            Begin Date
                                                        </Grid>
                                                        <Grid item>{director.begin_date || '-'}</Grid>
                                                    </Grid>
                                                    <Grid container flexDirection="row" my={0.5}>
                                                        <Grid item color="gray" sm={6}>
                                                            End Date
                                                        </Grid>
                                                        <Grid item>{director.end_date || '-'}</Grid>
                                                    </Grid>
                                                </Box>
                                            ))
                                        ) : (
                                            '-'
                                        )}
                                    </Grid>
                                </Grid>
                            </>
                        ) : (<>
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Company Name
                                    </Grid>
                                    <Grid item xs={6} sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                        {data?.data?.llp_master_data?.llp_name || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        LLPIN
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.llpin || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Email ID
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.email_id || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        LLP Status
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.llp_status || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Registered Address
                                    </Grid>
                                    <Grid item xs={6} sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                        {data?.data?.llp_master_data?.registered_address || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Main Division of Business Activity
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.main_division_of_business_activity_to_be_carried_out_in_india || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Description of Main Division
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.description_of_main_division || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Date of Incorporation
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.date_of_incorporation || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        ROC Code
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.roc_code || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        RD Region
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.rd_region || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Status under CIRP
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.status_under_cirp || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Number of Designated Partners
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.number_of_designated_partners || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Total Obligation of Contribution
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.total_obligation_of_contribution || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        LLP Strike Off/Amalgamated Date
                                    </Grid>
                                    <Grid item xs={6} sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                        {data?.data?.llp_master_data?.['llp_strike_off/_amalgamated_date'] || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Number of Partners
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.number_of_partners || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Previous Firm/Company Details (if applicable)
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.['previous_firm/_company_details,_if_applicable'] || '-'}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Directors/Signatory Details
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.['directors/signatory_details']?.length > 0 ? (
                                            data.data['directors/signatory_details'].map((director, index) => (
                                                <Box key={index} mb={2}>
                                                    <Divider sx={{ width: '100%' }} flexItem />
                                                    <Grid container flexDirection="row" my={0.5}>
                                                        <Grid item color="gray" sm={6}>
                                                            Name
                                                        </Grid>
                                                        <Grid item xs={6} sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                                            {director.name || '-'}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container flexDirection="row" my={0.5}>
                                                        <Grid item color="gray" sm={6}>
                                                            DIN/PAN
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {director['din/pan'] || '-'}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container flexDirection="row" my={0.5}>
                                                        <Grid item color="gray" sm={6}>
                                                            Designation
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {director.designation || '-'}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container flexDirection="row" my={0.5}>
                                                        <Grid item color="gray" sm={6}>
                                                            Begin Date
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {director.begin_date || '-'}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container flexDirection="row" my={0.5}>
                                                        <Grid item color="gray" sm={6}>
                                                            End Date
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {director.end_date || '-'}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            ))
                                        ) : (
                                            '-'
                                        )}
                                    </Grid>
                                </Grid>

                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Balance Sheets
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.balance_sheets?.length > 0 ? (
                                            data.data.llp_master_data.balance_sheets.map((sheet, index) => (
                                                <Box key={index} mb={1}>
                                                    <Divider sx={{ width: '100%' }} flexItem />
                                                    <Grid container flexDirection="row" my={0.5}>
                                                        <Grid item color="gray" sm={6}>
                                                            Financial Year
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {sheet.financial_year || '-'}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container flexDirection="row" my={0.5}>
                                                        <Grid item color="gray" sm={6}>
                                                            Date of Filing
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {sheet.date_of_filing || '-'}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            ))
                                        ) : (
                                            '-'
                                        )}
                                    </Grid>
                                </Grid>

                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Annual Returns
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.data?.llp_master_data?.annual_returns?.length > 0 ? (
                                            data.data.llp_master_data.annual_returns.map((returnData, index) => (
                                                <Box key={index} mb={1}>
                                                    <Divider sx={{ width: '100%' }} flexItem />
                                                    <Grid container flexDirection="row" my={0.5}>
                                                        <Grid item color="gray" sm={6}>
                                                            Financial Year
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {returnData.financial_year || '-'}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container flexDirection="row" my={0.5}>
                                                        <Grid item color="gray" sm={6}>
                                                            Date of Filing
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {returnData.date_of_filing || '-'}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            ))
                                        ) : (
                                            '-'
                                        )}
                                    </Grid>
                                </Grid>
                        </>)}
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>

            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="CIN / LLPIN Verification"
                        subheader="The API gets the company's master data using CIN (Company Identification Number)."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>CIN/LLPIN Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="ID Number"
                                    size="small"
                                    type="text"
                                    name="id_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message ||
                                                error?.data?.detail ||
                                                'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default CINVerification;