import React, { useState } from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    InputLabel,
    Modal,
    TextField,
    CircularProgress,
    Typography,
} from '@mui/material';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useFormik } from 'formik';
import { modalStyles as style } from '../utils/cssStyles';
import { useGetPanToFatherNameMutation } from '../features/api/deepApiSlice';

const PanToFatherName = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [getData, { data, isLoading }] = useGetPanToFatherNameMutation();

    const formik = useFormik({
        initialValues: {
            pan_number: '',
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const response = await getData({ pan_number: values.pan_number }).unwrap();
                if (response.code === 200) {
                    if (response.sub_code === 'INVALID_PAN_NUMBER') {
                        setErrorMessage('Invalid Pan Number.');
                    } else {
                        setErrorMessage('');
                        setModalOpen(true);
                    }
                }
            } catch (err) {
                setErrorMessage(err?.data?.message || 'Something went wrong, please try again.');
            }
        },
    });

    const handleCloseModal = () => {
        setModalOpen(false);
        formik.resetForm();
    };

    return (
        <>
            <Modal open={modalOpen} onClose={handleCloseModal}>
                <Box sx={{ ...style, width: '80vw', maxHeight: '70vh', overflowY: 'auto' }}>
                    <Box
                        mb={3}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Pan details fetched successfully &nbsp;
                        <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>

                    <Grid container mb={3} sx={{ overflowY: 'auto', maxHeight: '70vh' }}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Full Name
                            </Grid>
                            <Grid item>{data?.data?.full_name || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Father's Name
                            </Grid>
                            <Grid item>{data?.data?.father_name || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Individual Pan
                            </Grid>
                            <Grid item>
                                {data?.data?.individual_pan !== undefined
                                    ? data?.data?.individual_pan
                                        ? 'True'
                                        : 'False'
                                    : '-'}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader title="Pan to Father Name" subheader="Fetch & verify father's name from PAN Number." />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Pan Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Enter Pan Number"
                                    size="small"
                                    type="text"
                                    name="pan_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            {errorMessage && (
                                <Grid item>
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {errorMessage || 'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default PanToFatherName;
