import React, { useState } from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    InputLabel,
    Modal,
    TextField,
    CircularProgress,
    Typography,
} from '@mui/material';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useFormik } from 'formik';
import { modalStyles as style } from '../utils/cssStyles';
import { usePassportMutation } from '../features/api/deepApiSlice';

const Passport = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [getData, { data, isLoading }] = usePassportMutation();

    const formik = useFormik({
        initialValues: {
            file_number: '',
            dob: '',
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const response = await getData({ file_number: values.file_number, dob: values.dob }).unwrap();
                if (response.code === 200) {
                    if (response.sub_code === 'INVALID_INPUT') {
                        setErrorMessage(response.message || 'Invalid input. Please check your passport number and date of birth.');
                    } else {
                        setErrorMessage('');
                        setModalOpen(true);
                    }
                }
            } catch (err) {
                setErrorMessage(err?.data?.message || 'Something went wrong, please try again.');
            }
        },
    });

    const handleCloseModal = () => {
        setModalOpen(false);
        formik.resetForm();
    };

    return (
        <>
            <Modal open={modalOpen} onClose={handleCloseModal}>
                <Box sx={{ ...style }}>
                    <Box
                        mb={3}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Passport Verification Successful &nbsp;
                        <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>

                    <Grid container mb={3} >
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>Passport Number</Grid>
                            <Grid item>{data?.data?.file_number || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>Full Name</Grid>
                            <Grid item>{data?.data?.name || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>Date of Birth</Grid>
                            <Grid item>{data?.data?.dob || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>Application Type</Grid>
                            <Grid item>{data?.data?.application_type || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>Application Received Date</Grid>
                            <Grid item>{data?.data?.application_received_date || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>Status</Grid>
                            <Grid item>{data?.data?.status || '-'}</Grid>
                        </Grid>

                        <Grid container justifyContent="center">
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setModalOpen(false);
                                }}
                                color="secondary"
                                sx={{ padding: 0.5 }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>

            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Passport Verification"
                        subheader="Verify Indian passport by taking in the passport file number & DOB and receive verified passport details."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Passport File Number</InputLabel>
                                <TextField
                                    placeholder="Enter File Number"
                                    size="small"
                                    type="text"
                                    name="file_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.file_number}
                                    required
                                />
                            </Grid>

                            <Grid item>
                                <InputLabel>Date of Birth</InputLabel>
                                <TextField
                                    placeholder="Enter Date of Birth (yyyy-mm-dd)"
                                    size="small"
                                    type="date"
                                    name="dob"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.dob}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>

                            {errorMessage && (
                                <Grid item>
                                    <Alert severity="error">
                                        <AlertTitle>{errorMessage || 'Something went wrong, please try again.'}</AlertTitle>
                                    </Alert>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default Passport;
