import React, { useEffect, useState, useCallback } from 'react';
// icons
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import PaymentTwoToneIcon from '@mui/icons-material/PaymentTwoTone';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BusinessIcon from '@mui/icons-material/Business';
import PaymentIcon from '@mui/icons-material/Payment';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InsightsIcon from '@mui/icons-material/Insights';
import LaunchIcon from '@mui/icons-material/Launch';
import { Verified } from '@mui/icons-material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { Grid, CardHeader, Tabs, Tab, Divider, Typography } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';
import { Helmet } from 'react-helmet-async';
import HomeProductList from './HomeProductList';
import { useGetProductsQuery } from '../features/api/apiSlice';

const TABS = [
    {
        value: 'identity-verification',
        label: 'Identity Verification',
    },
    {
        value: 'mobile-number-intelligence',
        label: 'Mobile Number Intelligence',
    },
    {
        value: 'banking-verification',
        label: 'Banking Verification',
    },
    {
        value: 'employment-verification',
        label: 'Employment Verification',
    },
    {
        value: 'business-compliance',
        label: 'Business Compliance',
    },
    {
        value: 'document-verification',
        label: 'Document Verification',
    },
    {
        value: 'forensics',
        label: 'Forensics',
    },
];

const cardHeaderData = {
    identityVerification: {
        title: 'Identity Verification',
        subheader: ' Simplify identity verifications of individuals through Aadhaar, PAN, and other KYC solutions.',
    },
    mobileNumberIntellingence: {
        title: 'Mobile Number Intelligence',
        subheader:
            'Find, verify, and fetch multiple data points associated with a mobile number, including the Full Name, KYC Details, UPI ID, IFSC code, Network Details, Digital Age, Employment Information, and more.',
    },
    bankingVerification: {
        title: 'Banking Verification',
        subheader: "Ensure that customer's banking informations are genuine.",
    },
    businessCompliance: {
        title: 'Business Compliance',
        subheader: 'Check whether businesses follow necessary rules and regulations.',
    },
    documentVerification: {
        title: 'Document Verification',
        subheader: 'Validates that documents are real and unaltered.',
    },
    employmentVerification: {
        title: 'Employment Verification',
        subheader: "Confirm an individual's employment history and job status. ",
    },
    forensics: {
        title: 'Forensics',
        subheader: 'Analyse facial features and identities to support real customer onboarding.',
    },
};

const initialData = {
    identityVerification: [
        {
            id: 'Aadhaar-Basic',
            name: 'Aadhaar Basic',
            icon: <FingerprintIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/kyc-apis/aadhaar-verification-api-basic',
            explore: '/dashboard/verification/aadhaar/aadhaar-basic',
        },
        {
            id: 'Aadhaar-eKYC',
            name: 'Aadhaar E-KYC',
            icon: <FingerprintIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/offline-aadhaar-ekyc-api',
            explore: '/dashboard/verification/aadhaar-ekyc',
        },
        {
            id: 'Digilocker-E-Aadhaar',
            name: 'Digilocker E-Aadhaar',
            icon: <TextSnippetIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/digilocker-api',
            explore: '/dashboard/verification/digilocker',
        },
        {
            id: 'PAN-Basic',
            name: 'PAN Basic',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                    <path
                        fill="currentColor"
                        d="M22 3H2c-1.09.04-1.96.91-2 2v14c.04 1.09.91 1.96 2 2h20c1.09-.04 1.96-.91 2-2V5a2.074 2.074 0 0 0-2-2m0 16H2V5h20v14m-8-2v-1.25c0-1.66-3.34-2.5-5-2.5c-1.66 0-5 .84-5 2.5V17h10M9 7a2.5 2.5 0 0 0-2.5 2.5A2.5 2.5 0 0 0 9 12a2.5 2.5 0 0 0 2.5-2.5A2.5 2.5 0 0 0 9 7m5 0v1h6V7h-6m0 2v1h6V9h-6m0 2v1h4v-1h-4"
                    />
                </svg>
            ),
            learnMore: 'https://developers.deepvue.tech/reference/kyc-apis/pan-verification-api-basic',
            explore: '/dashboard/verification/pan',
        },
        {
            id: 'PAN-Plus',
            name: 'PAN Plus',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                    <path
                        fill="currentColor"
                        d="M22 3H2c-1.09.04-1.96.91-2 2v14c.04 1.09.91 1.96 2 2h20c1.09-.04 1.96-.91 2-2V5a2.074 2.074 0 0 0-2-2m0 16H2V5h20v14m-8-2v-1.25c0-1.66-3.34-2.5-5-2.5c-1.66 0-5 .84-5 2.5V17h10M9 7a2.5 2.5 0 0 0-2.5 2.5A2.5 2.5 0 0 0 9 12a2.5 2.5 0 0 0 2.5-2.5A2.5 2.5 0 0 0 9 7m5 0v1h6V7h-6m0 2v1h6V9h-6m0 2v1h4v-1h-4"
                    />
                </svg>
            ),
            learnMore: 'https://developers.deepvue.tech/reference/kyc-apis/pan-verification-api-plus',
            explore: '/dashboard/verification/pan-account',
        },
        {
            id: 'Pan-Aadhaar-Linking-Status',
            name: 'PAN Aadhaar Link',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                    <path
                        fill="currentColor"
                        d="M22 3H2c-1.09.04-1.96.91-2 2v14c.04 1.09.91 1.96 2 2h20c1.09-.04 1.96-.91 2-2V5a2.074 2.074 0 0 0-2-2m0 16H2V5h20v14m-8-2v-1.25c0-1.66-3.34-2.5-5-2.5c-1.66 0-5 .84-5 2.5V17h10M9 7a2.5 2.5 0 0 0-2.5 2.5A2.5 2.5 0 0 0 9 12a2.5 2.5 0 0 0 2.5-2.5A2.5 2.5 0 0 0 9 7m5 0v1h6V7h-6m0 2v1h6V9h-6m0 2v1h4v-1h-4"
                    />
                </svg>
            ),
            learnMore: 'https://developers.deepvue.tech/reference/kyc-apis/pan-aadhaar-link-status-api',
            explore: '/dashboard/verification/pan-aadhaar-link-status',
        },
        {
            id: 'Async-Driving-License',
            name: 'Driving License',
            icon: <DirectionsCarIcon />,
            learnMore:
                'https://developers.deepvue.tech/reference/async-apis/kyc-apis-async/get-driving-license-verification',
            explore: '/dashboard/verification/driving-license',
        },
        {
            id: 'Async-Voter-Id',
            name: 'Voter ID',
            icon: <ContactEmergencyIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/async-apis/kyc-apis-async/get-voter-id-verification',
            explore: '/dashboard/verification/voter-id',
        },
        {
            id: 'RC-Advanced',
            name: 'Vehicle RC Advanced',
            icon: <LocalShippingIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/kyc-apis/vehicle-rc-verification-api-advanced',
            explore: '/dashboard/verification/rc-adv',
        },
        {
            id: 'Passport-Verification',
            name: 'Passport Verification',
            icon: <FlightTakeoffIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/kyc-apis/passport-verification-api',
            explore: '/dashboard/verification/passport',
        },
        {
            id: 'Pan-to-Kra',
            name: 'PAN to KRA',
            icon: <BusinessCenterIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/kyc-apis/pan-to-kra',
            explore: '/dashboard/verification/pan-to-kra',
        },
        {
            id: 'PAN-To-Father-Name',
            name: 'PAN To Father Name',
            icon: <SupervisorAccountIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/kyc-apis/pan-to-father-name-api',
            explore: '/dashboard/verification/pan-to-father-name',
        }
    ],
    forensics: [
        {
            id: 'FaceMatch',
            name: 'Face Match',
            icon: <RememberMeIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/face-forensics/face-match-api',
            explore: '/dashboard/verification/facematch',
        },
        {
            id: 'Facial-Liveness-Check',
            name: 'Liveness Check',
            icon: <SensorOccupiedIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/face-forensics/liveness-check-api',
            explore: '/dashboard/verification/liveness-check',
        },
    ],
    businessCompliance: [
        {
            id: 'Gstin-By-Pan',
            name: 'GSTIN by PAN',
            icon: <BusinessIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/face-forensics/liveness-check-api',
            explore: '/dashboard/verification/gstin-pan/gstin-by-pan',
        },
        {
            id: 'GSTIN',
            name: 'GSTIN Basic',
            icon: <BusinessIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/gst-apis/gstin-verification-api-basic',
            explore: '/dashboard/verification/gstin/gstin-basic',
        },
        {
            id: 'GSTIN-Advanced',
            name: 'GSTIN Advanced',
            icon: <BusinessIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/gst-apis/gstin-verification-api-advanced',
            explore: '/dashboard/verification/gstin-advance/gstin-adv',
        },
        {
            id: 'Async-Udyam-Aadhaar',
            name: 'Udyam Aadhaar',
            icon: <BusinessIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/async-apis/msme/post-udyam-aadhaar-verification',
            explore: '/dashboard/verification/udyam',
        },
        {
            id: 'Async-Udyog-Aadhaar',
            name: 'Udyog Aadhaar',
            icon: <BusinessIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/async-apis/msme/post-udyog-aadhaar-verification',
            explore: '/dashboard/verification/udyog',
        },
        {
            id: 'CIN-Verification',
            name: 'CIN / LLPIN',
            icon: <ApartmentIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/mca-apis/cin-verification-api',
            explore: '/dashboard/verification/cin/cin-verification',
        },
        {
            id: 'DIN-Verification',
            name: 'DIN Verification',
            icon: <ApartmentIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/mca-apis/din-verification-api',
            explore: '/dashboard/verification/din-verification',
        },
        {
            id: 'PAN-MSME-Check',
            name: 'PAN MSME Check',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                    <path
                        fill="currentColor"
                        d="M22 3H2c-1.09.04-1.96.91-2 2v14c.04 1.09.91 1.96 2 2h20c1.09-.04 1.96-.91 2-2V5a2.074 2.074 0 0 0-2-2m0 16H2V5h20v14m-8-2v-1.25c0-1.66-3.34-2.5-5-2.5c-1.66 0-5 .84-5 2.5V17h10M9 7a2.5 2.5 0 0 0-2.5 2.5A2.5 2.5 0 0 0 9 12a2.5 2.5 0 0 0 2.5-2.5A2.5 2.5 0 0 0 9 7m5 0v1h6V7h-6m0 2v1h6V9h-6m0 2v1h4v-1h-4"
                    />
                </svg>
            ),
            learnMore: 'https://developers.deepvue.tech/reference/msme-apis/pan-udyam-msme-status-api',
            explore: '/dashboard/verification/pan-msme',
        },
        {
            id: 'TDS-Compliance',
            name: 'TDS Compliance',
            icon: <CreditScoreIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/taxpayer/tds-compliance-check-api-206ab-and-206cca',
            explore: '/dashboard/verification/tds-complaince',
        },
        {
            id: 'Fssai-Verification',
            name: 'FSSAI Verification',
            icon: <Verified />,
            learnMore: 'https://developers.deepvue.tech/reference/kyc-apis/vehicle-rc-verification-api-advanced',
            explore: '/dashboard/verification/fssai',
        },
    ],
    bankingVerification: [
        {
            id: 'Bank-Account',
            name: 'Bank Account',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                    <path
                        fill="currentColor"
                        d="m17.8 21.2l-2.8-3l1.2-1.2l1.6 1.6l3.6-3.6l1.2 1.4l-4.8 4.8M13 10h-3v7h2.1c.1-.8.5-1.6.9-2.3V10m3 0v2.3c.6-.2 1.3-.3 2-.3c.3 0 .7 0 1 .1V10h-3m-3.9 9H2v3h11.5c-.7-.8-1.2-1.9-1.4-3M21 6l-9.5-5L2 6v2h19V6M7 17v-7H4v7h3Z"
                    />
                </svg>
            ),
            learnMore: 'https://developers.deepvue.tech/reference/kyc-apis/bank-account-verification-api-penny-drop',
            explore: '/dashboard/verification/bank-account',
        },
        {
            id: 'IFSC',
            name: 'IFSC',
            icon: <AccountBalanceIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/kyc-apis/ifsc-verification-api',
            explore: '/dashboard/verification/ifsc',
        },
        {
            id: 'UPI',
            name: 'UPI',
            icon: <QrCode2Icon />,
            learnMore: 'https://developers.deepvue.tech/reference/kyc-apis/upi-verification-api',
            explore: '/dashboard/verification/upi/upi-basic',
        },
        {
            id: 'Mobile-To-Vpa',
            name: 'Mobile to VPA',
            icon: <PhonelinkLockIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/kyc-apis/mobile-to-upi-api',
            explore: '/dashboard/verification/mobile-to-vpa',
        },
        {
            id: 'Upi-Advanced',
            name: 'UPI Advanced',
            icon: <QrCode2Icon />,
            learnMore: 'https://developers.deepvue.tech/reference/kyc-apis/upi-advanced-verification-api-2',
            explore: '/dashboard/verification/upi-advanced',
        },
    ],
    documentVerification: [
        {
            id: 'Ind-Aadhaar-OCR',
            name: 'Aadhaar Card OCR',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 48 48">
                    <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.203 34.166c1.819-1.586 2.186-1.04 2.219 0"
                    />
                    <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.145 34.144c4.717-5.278 9.096-5.775 9.109.043"
                    />
                    <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.066 34.208c9.193-11.63 16.22-7.997 16.21.02"
                    />
                    <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M30.446 34.229c1.005-12.376-11.421-14.644-19.613-4.797m-.739.929c-.54.67-.827 1.085-.993 1.48"
                    />
                    <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M33.764 34.271c1.225-12.544-11.736-19.803-22.127-9.341m12.349 7.122l2.938-.74"
                    />
                    <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M36.892 34.271h2.916c-.1-7.11-3.933-11.71-10.398-14.54c3.052 2.318 8.067 6.414 7.482 14.54Zm-29.989.085L3.5 34.313c.888-.257 1.753-2.686 3.635-2.768c-.35.937-.446 1.874-.232 2.81ZM10.96 23.45c-1.34 1.468-2.343 3.105-2.895 4.967c-1.605-.85-1.026-3.905-1.923-4.438c.911.472 2.579-1.643 4.818-.528Zm2.24-2.155c1.379-1.446 2.95-2.56 4.946-2.938c-1.134-1.569-3.748-1.3-4.438-1.902c.016 1.6-.856 3.089-.507 4.84Zm7.968-3.699c1.977-.467 3.884-.419 5.748-.042c-.415-2.221-1.96-2.783-2.79-3.91c-.53.994-3.014 2.093-2.958 3.952h0Zm13.252-1.141c-1.871.94-3.022.506-4.355 2.008c1.92.498 3.568 1.404 4.925 2.747c.445-1.664-.46-3.198-.57-4.755Zm2.725 6.975c1.504 1.345 2.352 3.102 2.96 5.008c1.021-1.382 1.06-3.178 1.986-4.417c-1.585.166-3.255-1.177-4.946-.592Zm3.741 8.073a7.31 7.31 0 0 1 .38 2.832l3.234-.085c-.787-1.222-1.981-2.145-3.614-2.747Z"
                    />
                </svg>
            ),
            learnMore: 'https://developers.deepvue.tech/reference/document-ocr/india/aadhaar-ocr-api',
            explore: '/dashboard/verification/aadhaar-ocr',
        },
        {
            id: 'Ind-PAN-OCR',
            name: 'PAN Card OCR',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 48 48">
                    <g fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="4">
                        <rect width="40" height="32" x="4" y="8" rx="2" />
                        <path d="M17 25a4 4 0 1 0 0-8a4 4 0 0 0 0 8Z" />
                        <path strokeLinecap="round" d="M23 31a6 6 0 0 0-12 0m17-11h8m-6 8h6" />
                    </g>
                </svg>
            ),
            learnMore: 'https://developers.deepvue.tech/reference/document-ocr/india/pan-ocr-api',
            explore: '/dashboard/verification/pan-ocr',
        },
        {
            id: 'Ind-DL-OCR',
            name: 'Driving License OCR',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 2048 1536">
                    <path
                        fill="currentColor"
                        d="M896 1084q0 55-31.5 93.5T789 1216H363q-44 0-75.5-38.5T256 1084q0-54 7.5-100.5t24.5-90t51-68.5t81-25q64 64 156 64t156-64q47 0 81 25t51 68.5t24.5 90T896 1084zM768 640q0 80-56 136t-136 56t-136-56t-56-136t56-136t136-56t136 56t56 136zm1024 416v64q0 14-9 23t-23 9h-704q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h704q14 0 23 9t9 23zm-384-256v64q0 14-9 23t-23 9h-320q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h320q14 0 23 9t9 23zm384 0v64q0 14-9 23t-23 9h-192q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h192q14 0 23 9t9 23zm0-256v64q0 14-9 23t-23 9h-704q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h704q14 0 23 9t9 23zm128 832V256H128v1120q0 13 9.5 22.5t22.5 9.5h1728q13 0 22.5-9.5t9.5-22.5zm128-1216v1216q0 66-47 113t-113 47H160q-66 0-113-47T0 1376V160Q0 94 47 47T160 0h1728q66 0 113 47t47 113z"
                    />
                </svg>
            ),
            learnMore: 'https://developers.deepvue.tech/reference/document-ocr/india/driving-license-ocr-api',
            explore: '/dashboard/verification/driving-license-ocr',
        },
        {
            id: 'Ind-VoterId-OCR',
            name: 'Voter ID OCR',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 48 48">
                    <g fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="4">
                        <rect width="40" height="32" x="4" y="8" rx="2" />
                        <path d="M17 25a4 4 0 1 0 0-8a4 4 0 0 0 0 8Z" />
                        <path strokeLinecap="round" d="M23 31a6 6 0 0 0-12 0m17-11h8m-6 8h6" />
                    </g>
                </svg>
            ),
            learnMore: 'https://developers.deepvue.tech/reference/document-ocr/india/voter-id-ocr-api',
            explore: '/dashboard/verification/voter-id-ocr',
        },
        {
            id: 'Ind-Cheque-OCR',
            name: 'Bank Cheque OCR',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M5 14h14v1H5v-1m16 3V8H3v9h18M1 5h22v14H1V5m4 5h7v2H5v-2Z" />
                </svg>
            ),
            learnMore: 'https://developers.deepvue.tech/reference/document-ocr/india/bank-cheque-ocr-api',
            explore: '/dashboard/verification/cheque-ocr',
        },
        {
            id: 'Ind-Gstin-OCR',
            name: 'GSTIN Certificate OCR',
            icon: <BusinessIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/document-ocr/india/gstin-certificate-ocr-api',
            explore: '/dashboard/verification/gstin-ocr',
        },
        {
            id: 'Aadhaar-Masking',
            name: 'Aadhaar Masking',
            icon: <PaymentIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/document-forensics/aadhaar-masking-api',
            explore: '/dashboard/verification/aadhaar-masking',
        },
    ],
    mobileNumberIntellingence: [
        {
            id: 'Mobile-To-Name',
            name: 'Mobile to Name',
            icon: <PhonelinkLockIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/mobile-number-intelligence/mobile-to-name',
            explore: '/dashboard/verification/mobile-to-name',
        },
        {
            id: 'Mobile-To-Pan',
            name: 'Mobile to PAN',
            icon: <PhonelinkLockIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/mobile-number-intelligence/mobile-to-pan',
            explore: '/dashboard/verification/mobile-to-pan-details/mobile-to-pan',
        },
        // {
        //     id: 'Mobile-To-DL',
        //     name: 'Mobile To DL',
        //     icon: <PhonelinkLockIcon />,
        //     learnMore: 'https://developers.deepvue.tech/reference/mobile-number-intelligence/mobile-to-driving-license',
        //     explore: '/dashboard/verification/mobile-to-pan',
        // },
        {
            id: 'Mobile-To-Uan',
            name: 'Mobile to UAN',
            icon: <PhonelinkLockIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/mobile-number-intelligence/mobile-to-uan',
            explore: '/dashboard/verification/mobile-to-uan',
        },
        {
            id: 'Mobile-To-Uan-List',
            name: 'Mobile to UAN List',
            icon: <PhonelinkLockIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/mobile-number-intelligence/mobile-to-uan-list',
            explore: '/dashboard/verification/mobile-to-uan-list',
        },
        {
            id: 'Mobile-Network-Details',
            name: 'Mobile Network Details',
            icon: <PhonelinkLockIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/mobile-number-intelligence/mobile-network-details',
            explore: '/dashboard/verification/mobile-to-network-details',
        },
        {
            id: 'Mobile-To-Digital-Age',
            name: 'Mobile to Digital Age',
            icon: <PhonelinkLockIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/mobile-number-intelligence/mobile-to-digital-age',
            explore: '/dashboard/verification/mobile-to-digital-age',
        },
        {
            id: 'Mobile-To-Multiple-Vpa',
            name: 'Mobile To Multiple UPI',
            icon: <PhonelinkLockIcon />,
            learnMore: 'https://developers.deepvue.tech/reference/mobile-number-intelligence/mobile-to-multiple-upi',
            explore: '/dashboard/verification/mobile-to-multiple-upi',
        },
    ],
    employmentVerification: [
        {
            id: 'Aadhaar-To-Uan',
            name: 'Aadhaar to UAN',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                    <rect x="7" y="11" width="10" height="10" rx="2" ry="2" fill="currentColor" />
                    <path
                        d="M12 11V7a3 3 0 1 0-6 0v4"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <circle cx="12" cy="5" r="3" fill="currentColor" />
                    <path d="M12 8a4 4 0 0 1 4 4v1H8v-1a4 4 0 0 1 4-4z" fill="currentColor" />
                </svg>
            ),
            learnMore: 'https://developers.deepvue.tech/reference/epfo/aadhaar-to-uan',
            explore: '/dashboard/verification/aadhaar-to-uan',
        },
        {
            id: 'Pan-To-Uan',
            name: 'PAN to UAN',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                    <rect x="7" y="11" width="10" height="10" rx="2" ry="2" fill="currentColor" />
                    <path
                        d="M12 11V7a3 3 0 1 0-6 0v4"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <circle cx="12" cy="5" r="3" fill="currentColor" />
                    <path d="M12 8a4 4 0 0 1 4 4v1H8v-1a4 4 0 0 1 4-4z" fill="currentColor" />
                </svg>
            ),
            learnMore: 'https://developers.deepvue.tech/reference/epfo/pan-to-uan',
            explore: '/dashboard/verification/pan-to-uan',
        },
        {
            id: 'Uan-To-Employment-History',
            name: 'Employment History',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                    <rect x="7" y="11" width="10" height="10" rx="2" ry="2" fill="currentColor" />
                    <path
                        d="M12 11V7a3 3 0 1 0-6 0v4"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <circle cx="12" cy="5" r="3" fill="currentColor" />
                    <path d="M12 8a4 4 0 0 1 4 4v1H8v-1a4 4 0 0 1 4-4z" fill="currentColor" />
                </svg>
            ),
            learnMore: 'https://developers.deepvue.tech/reference/epfo/uan-to-employment-history',
            explore: '/dashboard/verification/uan-to-employ-history',
        },
    ],
};

export default function HomePage() {
    const { data: allProducts, isError: productsError, isLoading: productsIsLoading } = useGetProductsQuery();
    const [data, setData] = useState(initialData);
    const [activeTab, setActiveTab] = useState('identity-verification');

    useEffect(() => {
        if (allProducts && allProducts.productDetail) {
            const updatedData = { ...initialData };

            Object.keys(updatedData).forEach((category) => {
                updatedData[category] = updatedData[category].map((item) => {
                    const productDetail = allProducts.productDetail[item.id];
                    return {
                        ...item,
                        price: productDetail ? productDetail.cost_per_call : 11,
                        description: productDetail ? productDetail.description : '',
                        render: productDetail ? productDetail.render : false,
                    };
                });
            });

            setData(updatedData);
        }
    }, [allProducts]);

    console.log(data, 'data');

    const handleChangeTab = useCallback((event, newValue) => {
        setActiveTab(newValue);
    }, []);

    return (
        <>
            <Helmet>
                <title> Deepvue | API Dashboard </title>
            </Helmet>
            <Grid container textAlign="center" justifyContent="center" alignItems="left" flexDirection="column">
                <CardHeader
                    sx={{ marginBottom: 2, textAlign: 'left' }}
                    title={
                        <>
                            All Products
                            <br />
                            <Typography component="span" sx={{ color: 'blue', fontWeight: '600' }}>
                                Note: Per hit API cost excludes 18% GST
                            </Typography>
                        </>
                    }
                    subheader="Explore other products that might suit your business needs."
                />
            </Grid>

            <Grid>
                <Tabs
                    value={activeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChangeTab}
                    sx={{
                        width: 1,
                        left: 0,
                        zIndex: 9,
                        pl: 2,
                        borderRadius: 3,
                        [`& .${tabsClasses.flexContainer}`]: {
                            pr: { md: 3 },
                            justifyContent: {
                                md: 'flex-start',
                            },
                            alignContent: {
                                md: 'center',
                            },
                        },
                    }}
                >
                    {TABS.map((tab) => (
                        <Tab
                            key={tab.value}
                            value={tab.value}
                            label={tab.label}
                            sx={{
                                flexDirection: 'row',
                                gap: '12px',
                                color: '#637381',
                                '&.Mui-selected': {
                                    color: '#0E46A3',
                                    fontWeight: 'bold',
                                },
                                '&.Mui-focusVisible': {
                                    backgroundColor: 'black',
                                },
                                '&:hover': {
                                    color: '#0E46A3',
                                    opacity: 1,
                                },
                            }}
                        />
                    ))}
                </Tabs>
                <Divider
                    sx={{
                        height: '3px',
                        margin: '0px 15px 0px 15px',
                        backgroundColor: '#7743DB', // set the color of the divider  #FFE5CA
                    }}
                />
            </Grid>

            {allProducts && allProducts.productDetail && data ? (
                <Grid>
                    {activeTab === 'identity-verification' && (
                        <HomeProductList
                            data={data.identityVerification}
                            cardHead={cardHeaderData.identityVerification}
                        />
                    )}
                    {activeTab === 'forensics' && (
                        <HomeProductList data={data.forensics} cardHead={cardHeaderData.forensics} />
                    )}
                    {activeTab === 'business-compliance' && (
                        <HomeProductList data={data.businessCompliance} cardHead={cardHeaderData.businessCompliance} />
                    )}
                    {activeTab === 'banking-verification' && (
                        <HomeProductList
                            data={data.bankingVerification}
                            cardHead={cardHeaderData.bankingVerification}
                        />
                    )}
                    {activeTab === 'document-verification' && (
                        <HomeProductList
                            data={data.documentVerification}
                            cardHead={cardHeaderData.documentVerification}
                        />
                    )}
                    {activeTab === 'mobile-number-intelligence' && (
                        <HomeProductList
                            data={data.mobileNumberIntellingence}
                            cardHead={cardHeaderData.mobileNumberIntellingence}
                        />
                    )}
                    {activeTab === 'employment-verification' && (
                        <HomeProductList
                            data={data.employmentVerification}
                            cardHead={cardHeaderData.employmentVerification}
                        />
                    )}
                </Grid>
            ) : (
                <p>No data available.</p>
            )}
        </>
    );
}
